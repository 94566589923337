/* Responsive css start*/
.sidebar .dashboard-logo {
  display: none !important;
}
.sidebar .res-logo-img{
  display:none;
}
.responsive-topbar {
  display: none !important;
}
.sidebar .closemenu-toggle {
  display: none;
}
.profilesection1-content {
  justify-content: center;
  display: flex;
}
.res-profilesection2{
  display: none;
}
.res-percentage-content{
  display: none;
}

.border-faq{
  border-bottom: 1px solid #e2e1e1;
  margin: 0;
}
.responsive-jobshomemployeee {
  justify-content: space-around;
  display: flex;
}
.scroll-content-cde {
  height: 87%;
  overflow: auto;
}
.employer-profile-name{
  justify-content:center;
}
.has-search2{
	display: none;
}
.closemenu-toggle {
	display: none !important;
  }
  .edit-icon-input {
    font-size: 15px;
    padding: 3px;
    position: absolute;
    right: 15px;
    top: 12px;
    cursor: pointer;
    background-color: #0ba6b9;
}

.CircularProgressbar {
  width: 100%;
  position: relative;
}
.desk-form-inline{
  display:none;
}
ul a .material-symbols-rounded {
  align-items: center;
  display: flex;
  justify-content: center;
}
.map-section{
  position:relative;
}
.map-section-btn{
  position: absolute;
  top: 10px;
  right: 25px;
  z-index: 10000;
  background-color: #fff;
  padding: 8px;
  border-radius:5px;
}
.map-section-btn .rounded-left{
border-radius:5px 0px 0px 5px!important;
}
.map-section-btn .rounded-right{
  border-radius:0px 5px 5px 0px!important;
  }
  #logout-hover{
    background-color: #ffffff!important;
    color: #000000!important;
    border-radius: 4px;
  }
  #logout-hover:hover{
    background-color: #00abad!important;
    color: #ffffff!important;
    border-radius: 4px;
  }
  .google-input .dropdown-container input {
    height: 30px;
}
@media screen and (min-width:600px) and (max-width: 1100px) {
  .ci-bannerImage {
    width: 100%;
    height: 173px;
}
}
@media screen and (max-width: 1100px) {
  .topbar {
    display: none;
  }
}
@media screen and (max-width: 998px) {
  .profilesection1 {
    background-color: #fff;
    width: 100%;
    position: relative;
    border-radius: 0px;
    height: auto;
    top: 0px;
    margin-top: 70px;
    left: 0;
    box-shadow: 0px 3px 6px #00000017;
  }
  .profilesection2 {
    display: none;
  }
  .tab-section {
    display: flex;
    justify-content: space-evenly;
    background-color: #eaeaea00;
  }
  .profilesection2 .tab-section .tab {
    display: inline-block;
    border-right: 2px solid #00abad;
    padding: 0.5% 2%;
  }
  .tab-section .tab.active {
    border-right: 2px solid #fff0;
    background-color: #00abad;
    color: #fff;
    margin: 0;
    padding-top: 8px;
    padding: 8px 10px 2px;
  }
  .tab-section .tab.inactive {
    margin: 0% 0% 0%;
    padding-top: 8px;
    border-bottom: 4px solid #ffffff00;
  }
  .tab-section .tab-header {
    font-size: 14px;
  }
  .google-input label {
    font-size: 14px;
    top: -10px;
  }
  .google-input input:focus ~ label,
  .google-input input:not(:placeholder-shown) ~ label {
    top: -10px;
    font-size: 14px;
  }
  .progress-profile {
    width: 60px;
    height: 60px;
    font-size: 20px;
    line-height: 57px
  }
  .custom-border:before {
    display: none;
  }
  .custom-border2:after {
    display: none;
  }
  .custom-border:after {
    display: none;
  }
  .primary-body-content {
    height: 15vh;
  }
  .upload-gallery {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
  .primary-body-content2 {
    height: 45vh;
  }
  .scroll-content {
    height: auto;
    overflow-y: auto;
  }
  .profilesection1-content {
    justify-content: flex-start;
    display: flex;
    /* margin: 10px; */
  }
  .profilesection-content {
    margin: 10px;
  }
  .scroll-content-design {
    height: auto;
    overflow-y: auto;
  }
  .photo-icon-css {
    font-size: 11px;
  }
  .upload-gallery .material-symbols-rounded {
    font-size: 22px !important;
  }
  .profilesection2 .upload-btn-wrapper span.content {
    display: none;
  }
  .JHE-fixed-width {
	background-color: #fff;
    width: 100%;
    position: relative;
    border-radius: 6px;
    height: auto;
	margin-top: 70px;
    overflow-x: auto;
    top: 0;
    display: flex;
    left: 0;
    box-shadow: 0px 3px 6px #00000017;
  }
  .Jobssection2 {
    /* background-color: #fff; */
    width: 100%;
    position: relative;
    border-radius: 6px;
    left: 0;
    top: 0;
    height: auto;
    /* box-shadow: 0px 3px 6px #00000017; */
  }
  .JHE-fixed-width2 {
    display: none;
  }
  .JHE-fixed-width3 {
    display: none;
  }
  .responsive-jobshomemployeee {
	display: inline-flex;
    text-align: center;
	margin: 5px 0px;
  }
  .postjob-btn {
    font-size: 14px;
    padding: 6px 18px;
  }
  .rejected-btn {
    font-size: 14px;
    padding: 6px 18px;
  }
  .scroll-content-cde {
    height: 100%;
    overflow: auto;
}
.candidate-details-section {
    /* background-color: #fff; */
    width: 100%;
    position: relative;
    border-radius: 6px;
    height: 100%;
    top: 0;
    left: 0;
	margin-top: 70px;
    /* box-shadow: 0px 3px 6px #00000017; */
}
.dis-blk{
	display:block;
}
.Jobs-details-skills td, th {
    vertical-align: top;
    padding: 5px;
}
.has-search2{
	display: block;
}
.responsive-topbar {
	display: block !important;
  }
  .responsive-topbar .dashboard-logo .logo-dashboard-img {
    width: 65%;
    padding: 12px 0px;
    height: auto;
}
.responsive-topbar  .menu-icon{
	font-size: 35px;
    color: #000;
    font-weight: 500;
    vertical-align: middle;
}
.sidebar {
    display: none;
  }
.sidebar.active {
	display: block !important;
    width: 100%;
	z-index: 1000000000;
    position: fixed;
    top: 0%;
	padding: 10px;
    height: 100%;
}
  .closemenu-toggle {
    display: block !important;
    margin-left: auto;
    font-size: 25px;
    margin-bottom: 10px;
    background-color: #f6f6f6;
    color: #000;
  }
  .top-btn-desk{
	padding: 0.375rem 0.375rem;
	background-color: #fff;
  }
  .res-psc{
	display:none;
  }
  .profile-name-content p{
	font-size:12px;
  display: flex;
  }
  .res-percentage-content{
	display: block;
  }
  .res-profilesection2{
    display: block;
  }
  .res-profilesection2 .btn-link {
    font-weight: 600;
    color: #000000;
    font-size: 19px;
    background-color: transparent;
    display: flex;
    text-decoration: none;
    width: 100%;
}
.res-profilesection2 .card-header {
  padding: 0.175rem 1.25rem;
  margin-bottom: 8px;
  background-color: rgb(255 255 255 / 3%);
  border-bottom: 0px solid rgba(0,0,0,.125);
}
.sidebar .res-logo-img{
  display:block;
  background-color: #fff!important;
}
.res-profilesection2 .card {
  box-shadow: 0px 3px 6px #00000017;
  border: 1px solid rgb(0 0 0 / 5%);
}
.Modal-View {
  width: 99%;
}
.modal-content{
  margin-top: 10px;
}
button.btn.btn-link:hover {
  color: #00abad;
}
.del {
  padding: 5px;
  font-size: 17px;
}
.edit {
  padding: 5px;
  font-size: 17px;
}
/* .sidebar ul li {
  list-style-type: none;
  font-size: 20px;
  line-height: 1.5;
  padding-left: 3px;
}
.sidebar a {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
} */

.gallery-section img {
  height: auto;
  background-color: #f7f7f7;
  border-radius: 4px;
}
.employer-profile-name{
  justify-content: space-between;
}
.profilesection1-content .profile-name2 {
  width: 60px;
  height: 60px;
  border: 5px solid #00abad;
}
.profilesection1-content .profile-name2 h1 {
  font-size: 18px;
}
.photo-icon-css2 {
  padding: 2px;
  z-index: 300;
}

.photo-icon-css222 {
  margin-bottom:15px;
  padding: 1px;
  z-index: 300;
  position: relative;
}

.PP-upload-img {
  width: 50px;
    height: 50px;
}
.progress-profile .title {
  position: relative;
  z-index: 100;
  height: 60px;
}
.res-percentage-content h3{
  font-size: 22px;
}
.Jobssection1 {
  width: 100%;
  position: relative;
  height: auto;
  margin-top: 70px;
  top: 0;
  left: 0;
}
.Jobssection1 .table-view, .Jobssection1 .grid-view {
 display:none;
}
.Jobssection1 .res-form-inline {
display:none;
}
.res-btn-design{
  display:flex;
  justify-content: center;
  margin: 5px 0px;
}
.desk-form-inline{
  display: contents;
}
.jobs-details-section {
  width: 100%;
  position: relative;
  border-radius: 6px;
  height:auto;
  top: 0;
  margin-top: 70px;
  left: 0;
}
.jobs-details-section .Jobs-details-section1 {
  overflow-x: auto;
}
.sidebar ul li {
  display:block;
}
.faq-section {
  width: 100%;
  position: relative;
  border-radius: 6px;
  height: 100%;
  top: 0;
  margin-top: 70px;
  left: 0;
}
.login-btn {
  padding: 7px 10px;
}
.ce-section .login-btn {
  width: fit-content;
  margin: auto;
}
.mob-reverse-column{
  display: flex;
  flex-direction: column-reverse;
}
.banner-img-profile{
  width:100%;
  height: 173px;
}
.chat-section {
  /* background-color: #fff; */
  width: 100%;
  position: relative;
  border-radius: 6px;
  height: 100%;
  top: 0;
  left: 0;
  /* box-shadow: 0px 3px 6px #00000017; */
}
.chat-section .chat-text {
  width: 100%;
  position: relative;
  border-radius: 8px;
  height: 100%;
  top: 0;
  margin-top: 70px;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
}
.chat-open {
  /* background-color: #fff; */
  width: 100%;
  position: relative;
  border-radius: 8px;
  height: 100%;
  top: 0;
  margin-top: 70px;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
}
.chat-open .chat-open-img{
 display:none;
}
.chat-send .send-btn {
  width: 15%;
}
.chat-send input {
  width: 85%;
}
.ci-section {
  width: 100%;
  position: relative;
  height: 87%;
  top: 0;
  margin-top: 70px;
  left: 0;
}
.ci-bannerImage {
  width: 100%;
  height: 173px;

}
.ci-tab-section2 .section2 form {
  margin: 0px 2%;
}
.Companies-Reviews {
  background-color: rgba(255, 255, 255, 0);
}
.ci-logo-btn {
  top: -18px;
}
.insights-section {
  width: 100%;
  position: relative;
  margin-top: 70px;
  border-radius: 6px;
  height: 87%;
  left: 0;
  top: 0;
}
.insights-date-section1 h6 {
  height: 60px;
}
.insights-date-section2 h6 {
  height: 60px;
}
.static-date {
  position: relative;
  bottom: auto;
}
}

/* Responsive css end */
