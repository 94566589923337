@font-face {
  font-family: "Atkinson";
  src: url("../src/fonts/Atkinson_Hyperlegible/AtkinsonHyperlegible-Regular.ttf");
}
.filled-icon {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
body {
  background-color: #f3fafa;
  font-family: "Atkinson";
}
.logo-img {
  width: 162px;
}
.react-tel-input .form-control:focus {
  border-color: #00abad !important;
  box-shadow: 0 0 0 1px #00abad !important;
}
.react-tel-input .special-label {
  top: -10px !important;
  left: 12px !important;
  font-size: 15px !important;
  font-weight: normal !important;
}
.react-tel-input {
  margin: 24px 0px 0px 0px !important;
}
.react-tel-input .form-control::placeholder {
  font-weight: normal;
}
.react-tel-input .form-control {
  font-weight: bold;
  font-size: 12px !important;
  color: #000 !important;
  padding: 18.5px 14px 18.5px 16px !important;
}
.google-input-btm {
  padding-bottom: 20px;
  padding-top: 24px;
}
.verification-code--inputs input[type="text"] {
  border: 2px solid #e1e1e1;
  width: 46px;
  height: 46px;
  margin: 5px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 4px;
}
.top-12 {
  margin-top: 12px;
}
.chck-outline-hover {
  cursor: pointer;
}
.chck-outline-hover:hover {
  color: #00abad;
  cursor: pointer;
}
.chck-outline-active {
  color: #00abad;
}
.cursor {
  cursor: pointer;
}
.navbar {
  top: 0;
  position: fixed;
  width: 100%;
  /* height: 75px; */

  z-index: 1999;
  background-color: #ffff;
  box-shadow: 0px 3px 6px #00000017;
}
.has-search {
  position: relative;
}
.has-search .form-control {
  background-color: #eceff1;
  font-size: 16px;
  width: 100%;
  border-radius: 6px;
  padding: 8px 38px;
  border-color: #eceff1;
}
.has-search .form-control:focus {
  color: #495057;
  background-color: #eceff1;
  border-color: #eceff1;
  outline: 0;
  box-shadow: none;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
.has-search .form-control-feedback2 {
  position: absolute;
  z-index: 2;
  display: block;
  right: 0px;
  text-align: center;
  top: 0;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  /* pointer-events: none; */
  margin: 5px;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  /* background-color: #00abad; */
  background: linear-gradient(180deg, #00abad, #099dd1);
}
.has-search .form-control-feedback2:hover {
  color: #ffffff;
  background-color: #009594;
  border-color: #009594;
}
.has-search .form-control-feedback2:active {
  color: #ffffff !important;
  background-color: #099dd1 !important;
  border-color: #099dd1 !important;
}
.has-search .form-control-feedback2 .tune-form-control {
  position: absolute;
  /* z-index: 2; */
  /* display: block; */
  right: 0px;
  /* text-align: center; */
  top: 0;
  width: 2rem;
  height: 2rem;
  line-height: 1.9rem;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #909090;
  outline: 0;
  box-shadow: none;
}
.btnapplied {
  color: #00abad;
  font-size: 18px;
}
.login-btn {
  color: #ffffff;
  font-weight: 600;
  background-color: #00abad;
  border-color: #909090;
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 6px;
  cursor: pointer;
}
.sendmessage-btn {
  color: #00abad;
  font-weight: 600;
  background-color: #ffffff;
  /* border-color: #00abad; */
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 6px;
  cursor: pointer;
}
.login-btn:hover {
  color: #ffffff;
  background-color: #009594;
  border-color: #009594;
}
.login-btn:active {
  color: #ffffff !important;
  background-color: #099dd1 !important;
  border-color: #099dd1 !important;
}
.postjob-btn {
  color: #00abad;
  font-weight: 600;
  background-color: #f8f9fa00;
  border-color: #d9e9e9;
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 6px;
  cursor: pointer;
}
.postjob-btn:hover {
  color: #00abad;
  background-color: #eceff1;
  border-color: #d9dbdd;
}
.postjob-btn:active,
.postjob-btn.active {
  color: #ffffff !important;
  background-color: #099dd1 !important;
  border-color: #099dd1 !important;
}
.rejected-btn {
  color: #e75f6b;
  font-weight: 600;
  background-color: #f8f9fa00;
  border-color: #d9e9e9;
  font-size: 16px;
  padding: 8px 24px;
  border-radius: 6px;
}
.rejected-btn:hover {
  color: #e75f6b;
  background-color: #eceff1;
  border-color: #d9dbdd;
}
.rejected-btn:active {
  color: #ffffff !important;
  background-color: #e75f6b !important;
  border-color: #e75f6b !important;
}
.navbar .navbar-nav .nav-item .nav-link {
  color: #000;
  font-weight: 700;
  font-size: 16px;
}

/* banner start */

.Banner {
  background-image: url("../src/images/homebanner.png");
  margin-top: 73px;
  min-height: 90.5vh;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}
.section1 img {
  width: 150px;
  height: 150px;
  margin: auto 5%;
}
.section1 h1 {
  color: #fff;
  margin: 5%;
  font-weight: 600;
  font-size: 34px;
}
.section1 p {
  font-size: 16px;
  color: #fff;
  margin: 5%;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.section2 {
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 10px;
}
.section2 h5 {
  padding-top: 48px;
}
.section2 h6 {
  padding-top: 24px;
  padding-bottom: 12px;
}
.section2 .home-rdt-btn {
  border-radius: 5px;
  padding: 16px 0px;
  width: 100%;
  margin: 0px 0px 10px 0px;
  font-weight: bold;
  font-size: 16px;
  background-color: #fff;
  border: 1px solid #afb1b1;
}
.section2 .home-rdt-btn:hover {
  color: #212529;
  background-color: #f6f3f3;
  border-color: #afb1b1;
}
.section2 .home-rdt-btn:not(:disabled):not(.disabled).active,
.section2 .home-rdt-btn:not(:disabled):not(.disabled):active {
  color: #212529;
  background-color: #f6f3f3;
  border-color: #00abad;
  border-width: 3px;
}
.section2 .reg-text-clr {
  color: #00abad;
  font-weight: bold;
  cursor: pointer;
}
p .clr-blue {
  color: #00abad;
}
/* banner end */

/* form fields start */

.google-input {
  position: relative;
  margin: 24px 0px 0px 0px;
}
.google-input-pswd {
  margin: 12px 0;
}
.google-input input {
  position: relative;
  top: 0;
  left: 0px;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  background: #fff;
  border: 1px solid #cacaca;
  color: #000;
  font-size: 14px;
  padding: 0 16px;
  font-weight: bold;
}
.google-input textarea {
  position: relative;
  top: 0;
  left: 0px;
  width: 100%;
  /* height: 45px; */
  border-radius: 6px;
  background: #fff;
  border: 1px solid #cacaca;
  color: #000;
  font-size: 14px;
  padding: 0 16px;
  font-weight: bold;
}
input[aria-invalid="true"],
textarea[aria-invalid="true"] {
  border: 1px solid red;
}
.google-input input::placeholder {
  font-weight: normal;
  font-family: "Atkinson" !important;
  font-size: 14px;
}
.google-input textarea::placeholder {
  font-weight: normal;
  font-family: "Atkinson" !important;
  font-size: 14px;
}
.react-tel-input .form-control:hover {
  border-color: #cacaca !important;
}
.google-input label {
  position: absolute;
  left: 10px;
  top: -12px;
  /* top: -10px; */
  font-size: 16px;
  font-weight: 500;
  width: max-content;
  background: #fff;
  color: #000;
  padding: 0px 7px;
  border-radius: 10px;
  pointer-events: none;
  margin-bottom: 0px !important;
}
.google-input .css-6j8wv5-Input {
  margin: 0;
  padding: 0;
}
.google-input .css-319lph-ValueContainer {
  padding: 0px 16px;
}
.google-input .css-qc6sy-singleValue {
  font-weight: bold !important;
  font-size: 14px !important;
  text-align: left !important;
}
.google-input label.z-index-value {
  z-index: 1;
}
.google-input .css-1okebmr-indicatorSeparator {
  width: 0;
}
.google-input input:focus {
  border: 1px solid #00abad;
  outline: 1px solid #00abad;
}
.google-input textarea:focus {
  border: 1px solid #00abad;
  outline: 1px solid #00abad;
}
.google-input .custom-select:focus {
  border: 1px solid #00abad;
  outline: 1px solid #00abad;
}
/* .google-input input:focus ~ label,
.google-input input:not(:placeholder-shown) ~ label {
  top: -12px;
  font-size: 16px;
} */
.react-tel-input .flag-dropdown {
  display: none !important;
}
.react-tel-input .special-label {
  z-index: 0 !important;
}
/* .google-input input:focus ~ label {
  color: #00ABAD;
}
.google-input select:focus ~ label {
  color: #00ABAD;
} */
@keyframes diffuse {
  0%,
  100% {
    filter: drop-shadow(0 0 10px rgba(210, 240, 255, 0.2));
  }
  50% {
    filter: none;
  }
}
.fgtpwd {
  color: #00abad;
  text-decoration: underline;
  /* font-weight: bold; */
  cursor: pointer;
  background-color: transparent;
}
.fgtpwd:hover {
  color: #099dd1;
}
.pb-pt-form {
  padding-top: 12px;
  padding-bottom: 12px;
}
.lined-text {
  overflow: hidden;
  text-align: center;
}

.lined-text:before,
.lined-text:after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.lined-text:before {
  right: 0.5em;
  margin-left: -50%;
}

.lined-text:after {
  left: 0.5em;
  margin-right: -50%;
}
.img-icon img {
  width: 25px;
  height: 25px;
}
.img-icon {
  list-style-type: none;
  display: inline-block;
  margin: 5%;
  border: 1px solid #d7d7d7;
  padding: 10px;
  border-radius: 3px;
}
.img-icon:hover {
  background-color: #eee;
  border: 1px solid #d7d7d700;
}
.login-btm-padd {
  padding-bottom: 48px;
}
/* form fields end */

/* footer start */
.site-footer {
  background-color: #000000;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #d7d7d7;
}
.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
  padding-bottom: 10px;
}
.site-footer a {
  color: #737373;
}
.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
  padding-bottom: 10px;
}
.footer-links a {
  color: #d7d7d7;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #41ebdb;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: left;
}
.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
  border: 1px solid #757575;
}
.copyright-text {
  margin: 0;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #e4e4e4;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.twitter:hover {
  background-color: #00aced;
}
.social-icons a.linkedin:hover {
  background-color: #007bb6;
}
.social-icons a.dribbble:hover {
  background-color: #ea4c89;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}
/* footer end */

/*home page section start */
.homesection1 .section-content1 {
  background-color: #eceff1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.homesection1 .section-content1::after {
  border-bottom: 7px solid #028e8f;
  content: "";
  display: block;
  position: absolute;
  bottom: 10px;
  width: 50%;
  border-bottom-left-radius: 10px;
}
.homesection1 .section-content1 h4 {
  padding-top: 10%;
}
.homesection1 .section-content1 p {
  padding-top: 5%;
}
.homesection1 .section-content1 img {
  width: 194px;
}
.homesection1 .section-content2 img {
  width: 100%;
  height: auto;
}
.card-section img {
  width: 100%;
  height: auto;
}
.card-section .card-content {
  border-left: 7px solid #028e8f;
  border-radius: 5px;
}
.card-section .card-content p {
  font-size: 13px;
}
.homesection3 .section1content .sectionimg {
  width: 350px;
  height: auto;
}
.homesection3 .section1content {
  background-color: #1b0224;
  border-radius: 10px;
}
.homesection3 .sectioncontent {
  padding: 10%;
  color: #fff;
}
.homesection3 .section2content {
  background-image: url("../src/images/section3.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}
.homesection3 .section2content .innercontent {
  padding: 10%;
}
.homesection3 .section3content {
  background-image: url("../src/images/section2.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}
.homesection3 .section3content .innercontent {
  color: #ffff;
  padding: 12%;
}
.homesection3 .section4content {
  background-color: #028e8f;
  border-radius: 10px;
}
.homesection3 .section4content .sectionimg {
  width: 350px;
  height: auto;
}
.homesection3 .trainersection .innercontent img {
  width: 100px;
}
.homesection3 .trainersection {
  background-color: #ede7e7de;
  border-radius: 10px;
}
.homesection3 .trainersection .innercontent p {
  padding: 4% 8%;
}
.homesection3 .trainersection .innercontent .trainerimg {
  padding: 2% 5%;
}
.homesection4 .search-area {
  background: linear-gradient(99deg, #00abad, #099dd1);
  border-radius: 10px;
}
.homesection4 .search-area h3 {
  color: #fff;
}
.search-jobs-btn {
  background-color: #fff;
  border-radius: 25px;
  box-shadow: none;
  border-radius: 6px;
  color: #00abad;
}
.search-jobs-btn:hover {
  color: #00abad;
  background-color: #eceff1;
  border-color: #d9dbdd;
}
.search-jobs-btn:active {
  color: #ffffff !important;
  background-color: #099dd1 !important;
  border-color: #099dd1 !important;
}
.btn-light.focus,
.btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0);
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0);
}

.section1-regEmp {
  position: fixed;
  width: 40%;
}
.section1-regEmp h1 {
  color: #fff;
  margin: 5%;
  font-weight: 600;
}
/* home page section end */

/* dashboard Navbar start */
.dashboard-bg {
  margin: 0.5%;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.dashboard-header .center-d-flex-content {
  width: 100%;
}


.sidebar a {
  padding: 6px 6px 6px 6px;
  text-decoration: none;
  font-size: 20px;
  color: #000000;
  text-align: center;
  display: block;
  margin: 10px;
}

.sidebar a.active {
  /* background: linear-gradient(99deg,#00ABAD,#099DD1); */
  background-color: #00abad;
  color: #ffffff;
  border-radius: 4px;
}
.sidebar a:hover {
  /* background: linear-gradient(99deg,#00ABAD,#099DD1); */
  background-color: #00abad;
  color: #ffffff;
  border-radius: 4px 0px 0px 4px;
}

.main {
  position: "relative";
}
.sidebar ul li {
  list-style-type: none;
  font-size: 15px;
  line-height: 1;
  display: none;
  font-weight: 600;
}
.sidebar ul a:hover > li {
  display: block;
  width: 110px;
  margin-top: -30px;
  margin-left: 35px;
  color: #000;
  background-color: #00abad;
  color: #ffffff;
  text-align: start;
  padding: 10.5px 15px;
  position: fixed;
  z-index: 200000;
  border-radius: 0px 4px 4px 0px;
}
.sidebar ul {
  padding-inline-start: 0px;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}
.dashboard-logo .logo-dashboard-img {
  width: 40px;
  height: auto;
  /* margin: 23% 0%; */
}
.topbar .dashboard-logo {
  height: 10%;
  width: 5%;
  position: fixed;
  z-index: -1;
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(99deg, #00abad, #099dd1);
  box-shadow: 0px 3px 6px #00000017;
}
.topbar .dashboard-header {
  left: 6%;
  /* top: 10px; */
  height: 10%;
  padding: 7px 10px;
  border-radius: 8px;
  position: fixed;
      z-index: 0;
  background-color: #ffffff;
  width: 93.5%;
  box-shadow: 0px 3px 6px #00000017;
}
.dashboard-header-right .profile-name {
  border: 0px solid #00abad;
  background-color: #f3fafa;
  border-radius: 25px;
  width: 40px;
  text-align: center;
  height: 40px;
}
.has-search .dashboard-search {
  background-color: #f3fafa;
  font-size: 16px;
  width: 310px;
  border-radius: 6px;
  padding: 8px 38px;
  border-color: #eceff1;
}
.profilesection1-content .profile-name {
  /* border: 5px solid #00ABAD; */
  background-color: #f3fafa;
  border-radius: 50px;
  /* width: 90px; */
  text-align: center;
  /* height: 90px; */
  position: relative;
}
.profilesection1-content .profile-name2 {
  border: 6px solid #00abad;
  background-color: #f3fafa;
  border-radius: 50px;
  width: 90px;
  text-align: center;
  height: 90px;
  position: relative;
}
.profilesection1 {
  background-color: #fff;
  width: 93.5%;
  position: absolute;
  border-radius: 6px;
  height: 18%;
  top: 12%;
  left: 6%;
      z-index: -1;
  box-shadow: 0px 3px 6px #00000017;
}
.profilesection2 {
  background-color: #fff;
  width: 93.5%;
  position: absolute;
  border-radius: 6px;
  left: 6%;
      /* z-index: -1; */
  top: 31%;
  height: 68%;
  box-shadow: 0px 3px 6px #00000017;
}
.edit-icon {
  background-color: #fff;
  font-size: 15px;
  border: 1px solid #fff;
  border-radius: 13px;
  padding: 2px;
  cursor: pointer;
  height: fit-content;
  box-shadow: 0px 3px 6px #00000029;
  height: fit-content;
}
.progress-color {
  color: #e75f6b;
}
.progress-bar {
  background-color: #e75f6b;
}
.progress {
  height: 10px !important;
  border-radius: 25px !important;
}
.profile-name-content ul li {
  list-style-type: none;
  font-size: 15px;
}
.icon-middle {
  vertical-align: middle;
  cursor: pointer;
}

.photo-icon-css {
  position: absolute;
  top: 4%;
  right: 4%;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 4px;
  z-index: 1000;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
}
.custom-border {
  position: relative;
}
.custom-border2 {
  position: relative;
}
.custom-border2:after {
  content: " ";
  position: absolute;
  border-left: 2px #9e9e9e solid;
  top: 0%;
  right: 0;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.custom-border:after {
  content: " ";
  position: absolute;
  border-left: 2px #9e9e9e solid;
  top: 0%;
  right: 0;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.custom-border:before {
  content: " ";
  position: absolute;
  border-left: 2px #9e9e9e solid;
  top: 0%;
  left: 0;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.text-blue {
  color: #00abad;
}
.text-blue2 {
  color: #099dd1;
}
.sidebar {
  width: 5%;
  position: absolute;
  z-index: 200;
  top: 12%;
  height: 87%;
  /* left: 10px; */
  background-color: #fff;
  overflow-x: hidden;
  padding-top: 16px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000017;
}
/* circle progress start */
.progress-c {
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: none;
  /* margin: 0 auto; */
  box-shadow: none;
  position: relative;
}
.progress-c:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 7px solid #eee;
  position: absolute;
  top: 0;
  left: 0;
}
.progress-c > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress-c .progress-left {
  left: 0;
}
.progress-c .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 7px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #00abad;
}
.progress-c .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress-c .progress-right {
  right: 0;
}
.progress-c .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
}
.progress-c .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 36px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: 300;
}

/* This for loop creates the necessary css animation names Due to the split circle of progress-left and progress right, we must use the animations on each side. */
.progress-c[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-1 1.5s linear forwards;
}
.progress-c[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}
.progress-c[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-2 1.5s linear forwards;
}
.progress-c[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}
.progress-c[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-3 1.5s linear forwards;
}
.progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}
.progress-c[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-4 1.5s linear forwards;
}
.progress-c[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}
.progress-c[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress-c[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}
.progress-c[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress-c[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-1 1.5s linear forwards 1.5s;
}
.progress-c[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress-c[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.5s;
}
.progress-c[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress-c[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-3 1.5s linear forwards 1.5s;
}
.progress-c[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress-c[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-4 1.5s linear forwards 1.5s;
}
.progress-c[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-5 1.5s linear forwards;
}
.progress-c[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-5 1.5s linear forwards 1.5s;
}
@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg);
  }
}

/* circle progress end */

/* tab section start */
.tab-section {
  align-items: center;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #00abad;
}
.tab-section .tab-header {
  list-style-type: none;
  cursor: pointer;
}
.tab-section .tab.inactive {
  color: #000000;
  margin: 1% 2% 0%;
  padding-bottom: 1%;
  font-weight: bold;
  border-bottom: 4px solid #ffffff;
}

.tab-section .tab.active {
  color: #00abad;
  margin: 1% 2% 0%;
  padding-bottom: 1%;
  font-weight: bold;
  border-bottom: 4px solid #00abad;
}
/* tab section end */
/* dashboard Navbar end*/

/* profile page start */
.border-right {
  border-right: 1px solid #afb1b1;
}

.google-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
.scroll-content {
  height: 53vh;
  overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll-content::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.scroll-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-content-design {
  height: 50vh;
  overflow-y: auto;
}
.scroll-content-design::-webkit-scrollbar {
  width: 6px;
}
/* Track */
.scroll-content-design::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
.scroll-content-design::-webkit-scrollbar-thumb {
  background: #00aaad48;
  border-radius: 10px;
}
/* Handle on hover */
.scroll-content-design::-webkit-scrollbar-thumb:hover {
  background: #00aaad60;
}
.photo-icon-css2 {
  position: absolute;
  top: 4px;
  right: -12%;
  font-size: 16px;
  background-color: #f5f3f3;
  border-radius: 12px;
  padding: 4px;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  /* margin: 0px 25px; */
  /* display: inline-block; */
}
.profile-name2 .profile-icon {
  font-size: 55px;
}
.upload-gallery {
  border: 2px solid #00abad;
  color: #00abad;
  background-color: #f8ffff;
  padding: 8px 8px;
  border-radius: 4px;
  font-size: 18px;
  width: 100%;
}
.upload-gallery .material-symbols-rounded {
  font-size: 84px !important;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
::-webkit-file-upload-button {
  cursor: pointer;
}
button.btn.upload-gallery:hover {
  background-color: #c6f3f3;
}
.gallery-section img {
  width: 100%;
  height: 155px;
  position: relative;
}
.del-icon {
  font-size: 18px;
  padding: 2px;
  position: absolute;
  right: 15px;
  top: 5px;
  cursor: pointer;
  background-color: #e75f6b;
}
.banner-profile-dashboard {
  position: relative;
  /* background: rgb(33 33 33); */
}

.banner-profile-dashboard .bannerImage {
  opacity: 1;
  display: block;
  width: 100%;
  height: 173px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.banner-profile-dashboard-text {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.banner-profile-dashboard:hover img {
  opacity: 0.3;
}

.banner-profile-dashboard:hover .banner-profile-dashboard-text {
  opacity: 1;
  color: #000;
}

/* .banner-profile-dashboard-text .text label{
    background-color: indigo;
    color: white;
    padding: 0.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
    
} */

/* profile page end */

/* modal start */
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  /* overflow-x: hidden; */
  padding-top: 0%;
  /* overflow-y: auto; */
}

.modal-content {
  min-height: 200px;
  background-color: white;
  /* padding: 25px; */
}
.modalHeader {
  background-color: #e5f7f7;
  color: #00abad;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.succ-failure {
  background-color: #00abad;
  color: #ffffff;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.Modal-View {
  width: 65%;
  /* height: 700px; */
}
.modal-footer {
  background-color: #f2f2f2;
  justify-content: space-between;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.modal-scroll-content {
  height: 56vh;
  overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.modal-scroll-content::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.modal-scroll-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.modal-content .close-icon {
  position: absolute;
  right: -15px;
  top: -15px;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border-radius: 25px;
  border: 2px solid rgb(255, 255, 255);
  padding: 5px;
  cursor: pointer;
  z-index: 100;
}
.modal-content .close-icon:hover {
  border: 2px solid #000;
}
/* modal end */

/* jobs page start */
.Jobssection1 {
  background-color: #fff;
  width: 93.5%;
  position: fixed;
  border-radius: 6px;
  height: 10%;
  top: 12%;
  left: 6%;
  box-shadow: 0px 3px 6px #00000017;
}
.Jobssection2 {
  /* background-color: #fff; */
  width: 93.5%;
  position: fixed;
  border-radius: 6px;
  left: 6%;
  top: 23%;
  height: 76%;
  /* box-shadow: 0px 3px 6px #00000017; */
}
.blue {
  border-left: 4px solid #4491e3;
}
.pink {
  border-left: 4px solid #df48bd;
}
.violet {
  border-left: 4px solid #9643e7;
}
.green {
  border-left: 4px solid #00abad;
}
.jobs-content tr th {
  padding: 3px 8px;
  font-size: 14px;
}
.jobs-content tr td {
  font-size: 14px;
  vertical-align: top;
  padding: 3px 8px;
}
.jobs-content table {
  border-collapse: separate;
  border-spacing: 0 5px;
  width: 100%;
}
.jobs-content h5 {
  font-size: 18px;
}
.spantext {
  font-size: 16px;
  font-weight: 900;
}
.jobs-content h6 {
  font-size: 14px;
}
.jobs-content small {
  font-size: 12px;
}
.jobs-content {
  background-color: #fff;
  border-radius: 7px;
  padding: 5%;
  margin: 4px 0px;
  box-shadow: 0px 3px 6px #00000017;
  position: relative;
  height: 100%;
}
.jobs-edit-icon {
  background-color: #fff0;
  font-size: 20px;
  border: 1px solid #d2cece00;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  right: 20px;
  top: 15px;
  position: absolute;
  box-shadow: 0px 3px 6px #00000000;
}
.jobs-padding {
  padding-right: 4px;
  padding-left: 4px;
  margin-bottom: 8px;
}
.jobs-content-button p a {
  color: #000000;
  text-decoration: underline;
}
.jobs-content-button p a:hover {
  color: #007bff;
  text-decoration: underline;
}
.Jobssection1 select {
  background-color: #eceff1;
  border-color: #eceff1;
  color: #000;
  width: 100%;
}
.Jobssection1 select:focus {
  background-color: #eceff1;
  border-color: #eceff1;
  color: #000;
  outline: 0;
  box-shadow: none;
}
.edudetails-section {
  cursor: pointer;
}
.modalcenter {
  margin: auto;
}
.table-view {
  background-color: #ffffff;
  box-shadow: 0px 4px 12px 2px #00000017;
  border-radius: 7px;
  padding: 11%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* vertical-align: middle; */
  align-items: center;
  cursor: pointer;
}
.table-view:hover {
  background-color: #00abad;
  color: #ffffff;
}
.table-view.active {
  background-color: #00abad;
  color: #ffffff;
}
.grid-view {
  background-color: #ffffff;
  border-radius: 7px;
  padding: 11%;
  height: 100%;
  box-shadow: 0px 3px 6px #00000017;
  cursor: pointer;
  color: #00abad;
  display: flex;
  justify-content: center;
  /* vertical-align: middle; */
  align-items: center;
}
.grid-view.active {
  background-color: #00abad;
  color: #ffffff;
}

.Jobssection2 .table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #ffffff00;
}
.Jobssection2 .table td,
.table th {
  padding: 5px 10px;
  vertical-align: top;
  border-top: 1px solid #dee2e600;
}
.Jobssection2 table {
  border-collapse: separate;
  border-spacing: 0 5px;
  width: 100%;
  /* height: 100%; */
  background-color: #fff;
  /* box-shadow: 0px 3px 6px #00000017; */
  border-radius: 6px;
  padding: 5px;
  display: inline-table;
  align-items: center;
}
table td a {
  color: #000000;
  text-decoration: underline;
}
.location-font {
  font-size: 14px;
}

.Toggle-Switch {
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
  box-shadow: 0px 3px 6px #99989894;
}

.switch input:checked + .slider {
  background-color: #00abad;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #00abad61;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}
.redirect-link {
  color: #000000;
  text-decoration: none;
}
.redirect-link:hover {
  color: #000000;
  text-decoration: none;
}
/* Rounded sliders */
.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}
.color-orange {
  color: #ffb230;
}
.Jobssection2 .table-v {
  box-shadow: 0px 3px 6px #00000017;
}
/* jobs page start */

/* jobs details section start */
.jobs-details-section {
  /* background-color: #fff; */
  width: 93.5%;
  position: fixed;
  border-radius: 6px;
  height: 87%;
  top: 12%;
  left: 6%;
  /* box-shadow: 0px 3px 6px #00000017; */
}
.Jobs-details-section1 {
  background-color: #fff;
  width: 100%;
  /* position: absolute; */
  border-radius: 6px;
  height: 10%;
  /* top: 12%; */
  left: 6%;
  box-shadow: 0px 3px 6px #00000017;
}
.Jobs-details-job-name {
  background-color: #fff;
  width: 100%;
  border-radius: 6px;
  left: 6%;
  box-shadow: 0px 3px 6px #00000017;
}
.Jobs-details-skills {
  background-color: #fff;
  width: 100%;
  border-radius: 6px;
  left: 6%;
  box-shadow: 0px 3px 6px #00000017;
}
.Jobs-details-skills li {
  display: inline-block;
  padding-top: 4px;
  font-size: 14px;
  padding-right: 4px;
}
.Jobs-details-skills li:before {
  content: "\2022";
  color: #00abad;
  font-weight: bold;
  display: inline-block;
  width: 8px;
}

.resume-details-content .profile-name {
  border: 5px solid #00abad;
  background-color: #f3fafa;
  border-radius: 50px;
  width: 65px;
  text-align: center;
  height: 65px;
  position: relative;
}
.match .color-blue {
  background-color: #00abad;
  color: #fff;
  padding: 4px 10px;
  border-radius: 25px;
}
.match .color-red:active {
  background-color: #e75f6b;
  color: #fff;
  padding: 4px 10px;
  border-radius: 25px;
}
.match .color-yellow:active {
  background-color: #e7e75f;
  color: #000000;
  padding: 4px 10px;
  border-radius: 25px;
}
.match .color-green {
  background-color: #2dca1c;
  color: #fff;
  padding: 4px 10px;
  border-radius: 25px;
}
.Jobs-details-skills2 li {
  display: inline-block;
  padding: 2px 8px;
  font-size: 14px;
  margin: 3px;
  background-color: #b4e1f1;
  border-radius: 25px;
}
.Jobs-details-skills2 .sec-skills li {
  display: inline-block;
  padding: 2px 8px;
  font-size: 14px;
  margin: 3px;
  background-color: #e9e9e9;
  border-radius: 25px;
}
/* jobs details section end */

/* candidate profile css start */

.candidate-details-section {
  /* background-color: #fff; */
  width: 93.5%;
  position: fixed;
  border-radius: 6px;
  height: 76%;
  top: 12%;
  left: 6%;
  /* box-shadow: 0px 3px 6px #00000017; */
}
.candidate-profile-section1 {
  background-color: #fff0;
  width: 91.5%;
  position: fixed;
  border-radius: 6px;
  height: 10%;
  bottom: 1%;
  left: 7%;
}
.btn-bg-color {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000017;
  height: 10%;
}
.email-color {
  color: #00abad;
}
.Jobs-details-skills .profile-name {
  border: 5px solid #00abad;
  background-color: #f3fafa;
  border-radius: 50px;
  width: 90px;
  text-align: center;
  height: 79px;
  position: relative;
}
.Jobs-details-skills td,
th {
  vertical-align: top;
  padding-top: 5px;
  padding-left: 5px;
}
.Jobssection2 td,
.Jobssection2 td {
  vertical-align: top;
  padding-top: 5px;
}
.Jobssection3 td,
.Jobssection3 td {
  vertical-align: top;
  padding: 5px;
}
.resume-section img {
  width: 100%;
  height: auto;
}

/* progress bar start */
.navigation_menu {
  font-size: 14px;
  margin-top: 5px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
  border-radius: 6px;
}

.navigation_tabs {
  counter-reset: step;
  position: relative;
  padding-left: 45px;
  list-style: none;
}

.navigation_tabs::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #ccc;
}

.navigation_menu ul {
  list-style-type: none;
  padding-right: 0;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0px;
  margin-bottom: 0;
}

.navigation_menu li {
  position: relative;
  counter-increment: list;
}

.navigation_menu li:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}

.navigation_menu li:after {
  content: counter(step);
  counter-increment: step;
  display: inline-block;
  position: absolute;
  top: 0;
  left: -37px;
  width: 22px;
  height: 22px;
  line-height: 26px;
  border: 1px solid #ddd;
  border-radius: 50%;
  color: #ccc;
  background-color: #ccc;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
}

.navigation_menu li:not(:last-child) {
  padding-bottom: 100px;
}

.navigation_menu li.tab_inactive:before {
  border-left: 3px solid #00abad;
  margin-left: 3px;
}

.navigation_menu li.tab_active:after {
  border: 1px solid #00abad;
}
.navigation_menu li.tab_inactive_danger:after {
  content: " ";
  font-size: 20px;
  color: #fff;
  text-align: center;
  border: 1px solid #e75f6b;
  background-color: #e75f6b;
}
.navigation_menu li.tab_inactive:after {
  content: " ";
  font-size: 20px;
  color: #fff;
  text-align: center;
  border: 1px solid #00abad;
  background-color: #00abad;
}
.navigation_tabs li a,
.navigation_tabs li a {
  display: block;
  /* padding-top: 8px; */
  text-decoration: none;
  color: #000;
}

.navigation_tabs li.tab_inactive a {
  color: #000;
  font-weight: bold;
}

.navigation_tabs li.tab_disabled a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

.navigation_tabs li.tab_active a:hover,
.navigation_tabs li.tab_inactive a:hover {
  font-weight: bold;
}
/* progress bar end */

/* candidate profile css end */

/* chat css start */
.chat-section {
  /* background-color: #fff; */
  width: 93.5%;
  position: fixed;
  border-radius: 6px;
  height: 87%;
  top: 12%;
  left: 6%;
  /* box-shadow: 0px 3px 6px #00000017; */
}
.chat-section .chat-text {
  width: 30.5%;
  position: fixed;
  border-radius: 8px;
  height: 87%;
  top: 12%;
  left: 6%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
}

.chat-search {
  background-color: #def1f1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px 3px 6px #00000017;
}
.chat-search h6 {
  color: #00abad;
}
.all-chat {
  background-color: #f3f3f330;
  border-top: 2px solid #e1e1e18f;
}
.all-chat:hover,
.all-chat.active {
  background-color: #00abad;
  color: #fff;
}
.all-chat-section {
  height: 92%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* box-shadow: 0px 3px 6px #00000017; */
}
.chat-open {
  /* background-color: #fff; */
  width: 62.5%;
  position: fixed;
  border-radius: 8px;
  height: 87%;
  top: 12%;
  left: 37%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
}
.chat-search .chat-open-text h6 {
  color: #000000;
}
.chat-open-section {
  height: 85%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;
  /* box-shadow: 0px 3px 6px #00000017; */
}
.chat-open-section h6 span {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
  border-radius: 25px;
}

.chat-send {
  width: 100%;
  height: 45px;
  display: flex;
  position: absolute;
  bottom: 15px;
}
.chat-send input {
  width: 94%;
  text-decoration: none;
  background-color: #f6f6f6;
  border: none;
  padding-left: 1rem;
}
.chat-send input:focus {
  outline: none;
  background-color: #f6f6f6;
  border-color: #f6f6f6;
}
.chat-send .send-btn {
  width: 6%;
  border: none;
  color: white;
  background-color: #00abad;
  margin-left: 5px;
  border-radius: 5px;
}
.chat-send .send-btn:hover {
  cursor: pointer;
}
.chat-send .send-btn:focus {
  outline: none;
}
.chat-open-section .message-left {
  width: fit-content;
}
.chat-open-section .message-right {
  width: fit-content;
  margin: 0px 0px 5px auto;
}
.message-left .chat-left {
  background-color: #f1f1f1;
  padding: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.message-right .chat-right {
  background-color: #def1f1;
  padding: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
/* chat css end */

#searchTray {
  width: 100%;
  padding: 10px;
  text-align: center;
  background-color: white;
  margin-top: 20px;
  position: absolute;
  top: 53%;
  z-index: 1000;
  box-shadow: 0px 3px 15px #00000017;
  border-radius: 8px;
}
.css-14el2xx-placeholder {
  font-size: 14px !important;
  text-align: left !important;
}

@media screen and (min-width: 780px) and (max-width: 1100px) {
  .homesection3 .trainersection .innercontent img {
    width: 75px;
  }
  .homesection3 .section4content .sectionimg {
    width: 100%;
    height: auto;
  }
  .homesection3 .section1content .sectionimg {
    width: 100%;
    height: auto;
  }
  .homesection1 .section-content1 img {
    display: none;
  }
  .homesection1 .section-content1 h4 {
    padding-top: 3%;
    font-size: 19px;
  }
  .homesection1 .section-content1 p {
    padding-top: 0%;
  }
}

@media screen and (max-width: 778px) {
  .card-section .card-content p {
    font-size: 18px;
  }
  .homesection3 .section1content .sectionimg {
    width: 100%;
    height: auto;
  }
  .homesection3 .section4content .sectionimg {
    width: 100%;
    height: auto;
  }
  .homesection3 .trainersection .innercontent img {
    width: 70px;
  }
}

/* venkat */

.modalDisapprove-details {
  background-color: #cfcccc9d;
  border: solid 1px rgba(128, 128, 128, 0.252);
  border-bottom: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.modalDisapprove-butn {
  background-color: #9e9e9e67;
  color: #000000de;
  border-radius: 20px;
  border-style: none;
  font-size: 13px;
  transition: all 0.4s;
  padding: 3px 12px;
  padding-top: 4px;
  padding-right: 6px;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgb(255 255 255 / 0%);
}
.modalDisapprove-butn:hover {
  background-color: #00abad;
  color: #ffffff;
  border-style: none;
  transition: all 0.4s;
}
.modalDisapprove-butn:focus {
  box-shadow: 0 0 0 0.2rem rgb(108 117 125 / 0%);
}

.modalDisapprove-lockicon {
  position: relative;
  top: 3px;
  font-size: 15px;
}
.modalDisapprove-textarea {
  resize: none;
  border-top: hidden;
  border-radius: 0px 0px 4px 4px;
  border: solid 1px rgba(128, 128, 128, 0.252);
}
.modalDisapprove-textarea:focus {
  color: #000000;
  background-color: #fff;
  border-color: rgba(128, 128, 128, 0.252);
}
.modalClosing-btn {
  letter-spacing: 1px;
}

/* employeee css start */

/* profile start */
.bg-color-green {
  background-color: #b2e6e6;
  border-radius: 26px;
  display: inline-block;
  line-height: 2;
  color: #4d4d4d;
}

.bg-color-green:hover {
  background-color: #00abad;
  color: #fff;
}
.bg-color-yellow {
  background-color: #f5ffc5;
  border-radius: 26px;
  display: inline-block;
  line-height: 2;
  color: #4d4d4d;
}
.bg-color-grey {
  background-color: #e4e4e4;
  border-radius: 26px;
  display: inline-block;
  line-height: 2;
  color: #4d4d4d;
}
.bg-color-grey:hover {
  background-color: #00abad;
  color: #fff;
}
.bg-color-yellow:hover {
  background-color: #ebff85;
}
.edudetails-card {
  background-color: #e5f7f7;
  border-radius: 4px;
  padding: 12px;
  font-size: 15px;
}
.edit {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 2px;
}
.edit:hover {
  background-color: #00abad;
  color: #fff;
}
.del {
  background-color: #ffffff;
  border-radius: 4px;
  padding: 2px;
}
.del:hover {
  background-color: #e75f6b;
  color: #fff;
}
.down-pdf {
  padding: 5%;
  background-color: #c6f3f3;
  color: #00abad;
  display: flex;
  text-align: center;
  vertical-align: middle;
  flex-direction: column;
  border-radius: 4px 0px 0px 4px;
}
.down-pdf:hover {
  background-color: #00abad;
  color: #fff;
  cursor: pointer;
}
.down-pdf span {
  font-size: 40px;
}
.delete-content {
  padding: 14% 6%;
  background-color: #ffd2d68c;
  color: #e75f6b;
  display: flex;
  text-align: center;
  flex-direction: column;
  border-radius: 0px 4px 4px 0px;
}
.delete-content span {
  font-size: 30px;
}
.delete-content:hover {
  background-color: #e75f6b;
  color: #fff;
}
.hori-mor {
  position: absolute;
  top: 1%;
  right: 23%;
  cursor: pointer;
}
.primary-header {
  background-color: #bdb9b980;
  color: #000;
  border-radius: 8px 8px 0px 0px;
}
.primary-card {
  background-color: #e9e9e980;
  border-radius: 8px;
}
.primary-body-content {
  height: 25vh;
}
.bg-section {
  background-color: #fff;
  padding: 0.8% 0px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000017;
}
.jobs-eye-icon {
  background-color: #fff0;
  font-size: 20px;
  border: 1px solid #d2cece00;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  right: 45px;
  top: 15px;
  position: absolute;
  box-shadow: 0px 3px 6px #00000000;
}
.compworked-img {
  width: 90px;
  height: 90px;
  padding: 15px;
  margin: auto 0;
  text-align: center;
  border-radius: 6px;
  background-color: #f4f4f4;
}
.text-color-blue {
  color: #4491e3;
}
.table-profile {
  background-color: #fff;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000017;
}
/* profile end */

/* employeee css end */

/* faq page design start */
.faq-section {
  background-color: #fff;
  width: 93.5%;
  position: fixed;
  border-radius: 6px;
  height: 87%;
  top: 12%;
  left: 6%;
  /* overflow: auto; */
  box-shadow: 0px 3px 6px #00000017;
}
.faq-heading {
  box-shadow: 0px 3px 6px #00000017;
  padding: 10px;
  border-radius: 5px;
}
.faq-content {
  box-shadow: 0px 3px 6px #00000017;
  border-radius: 5px;
}
.faq-body {
  padding: 15px;
  border-radius: 5px;
}
.text-green {
  color: #00abad;
}
.text-grey {
  color: #6b6b6b;
}
.faq-button {
  padding: 15px;
  background-color: #9e9e9e14;
  border-radius: 0px 0px 5px 5px;
}
.faq-section .form-control:focus {
  color: black;
  background-color: #fff;
  border-color: #909090;
  outline: 0;
  box-shadow: none;
}
.faq-checkbox:hover {
  color: #00abad;
  cursor: pointer;
}
.faq-checkbox:active,
.faq-checkbox.active {
  color: #00abad;
}
.faq-thumbs-up {
  color: #00abad;
  background-color: #fff;
  border: 1px solid #e8e4e4;
  border-radius: 6px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
}
.chat-bubble {
  color: #4491e3;
  background-color: #fff;
  border: 1px solid #e8e4e4;
  border-radius: 6px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
}
.faq-thumbs-down {
  color: #e75f6b;
  background-color: #fff;
  border: 1px solid #e8e4e4;
  border-radius: 6px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: pointer;
}
.faq-thumbs-up:hover,
.faq-thumbs-up.active {
  color: #fff;
  background-color: #00abad;
}
.faq-thumbs-down:hover,
.faq-thumbs-down.active {
  color: #fff;
  background-color: #e75f6b;
}
.chat-bubble:hover,
.chat-bubble.active {
  color: #fff;
  background-color: #4491e3;
}
.rate-area {
  padding-top: 5px;
  border-style: none;
}

.rate-area:not(:checked) > input {
  position: absolute;
  top: -9999px;
  clip: rect(0, 0, 0, 0);
}

.rate-area:not(:checked) > label {
  float: right;
  width: 0.8em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 180%;
  color: lightgrey;
  margin-bottom: 0px;
}

.rate-area:not(:checked) > label:before {
  content: "★";
}

.rate-area > input:checked ~ label {
  color: #e75f6b;
}

.rate-area:not(:checked) > label:hover,
.rate-area:not(:checked) > label:hover ~ label {
  color: #e75f6b;
}

.rate-area > input:checked + label:hover,
.rate-area > input:checked + label:hover ~ label,
.rate-area > input:checked ~ label:hover,
.rate-area > input:checked ~ label:hover ~ label,
.rate-area > label:hover ~ input:checked ~ label {
  color: #e75f6b;
}
.dis-in-reviews {
  display: inline-block;
}
.reviews-box-content {
  background-color: #e9e9e97a;
  border-radius: 5px;
  padding: 5px;
}
.reviews-heading {
  box-shadow: 0px 3px 6px #00000017;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
/* faq page design end */

/* employee-navbar */

.addLanguage-skills {
  border-radius: 26px;
  border-style: none;
  background: #e4e4e4;
  color: #000000de;
}
.addLanguage-skills.active {
  content: "";
  background: #00abad;
  color: #ffffff;
}

.employeeNavbar-labels {
  color: #000000;
}
.form-check input.larger {
  width: 30px;
  height: 20px;
}
.comp-icon {
  font-size: 70px;
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
  color: #00abad;
  background-color: #fff;
}
.bg-color-skills {
  display: inline-block;
  padding: 2px 8px !important;
  font-size: 14px;
  margin: 3px;
  background-color: #b4e1f1;
  border-radius: 25px;
}
/* venkat employee-navbar */

/*profile progress start */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.progress-profile {
  width: 90px;
  height: 90px;
  font-size: 28px;
  font-weight: bold;
  color: #000;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: #f3fafa;
  text-align: center;
  line-height: 85px;
  margin: 0px;
}

/* .progress::after {content: "%";} */
* {
  box-sizing: border-box;
}
.progress-profile .title {
  position: relative;
  z-index: 100;
  height: 90px;
}

.progress-profile .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #f3fafa;
}
.progress-profile .title {
  color: #000;
}
.progress-profile .left,
.progress-profile .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 5px solid #00abad;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
  /* z-index: 1000; */
}
.progress-add-icon {
  position: relative;
}
.progress-profile .left {
  animation: load1 1s linear forwards;
}

.progress-profile:nth-of-type(2) .right,
.progress-profile:nth-of-type(3) .right {
  animation: load2 0.5s linear forwards 1s;
}

.progress-profile:last-of-type .right,
.progress-profile:first-of-type .right {
  animation: load3 0.8s linear forwards 1s;
}

@keyframes load1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

@keyframes load2 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(270deg);
  }
}

@keyframes load3 {
  0% {
    z-index: 100;
    transform: rotate(180deg);
  }

  100% {
    z-index: 100;
    transform: rotate(315deg);
  }
}

.progress-profile2 {
  width: 40px;
  height: 40px;
  font-size: 15px;
  font-weight: bold;
  color: #000;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background: #f3fafa;
  text-align: center;
  line-height: 37px;
  /* margin: 20px */
}

.progress-profile2 .title {
  position: relative;
  z-index: 100;
  height: 38px;
}

.progress-profile2 .overlay {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /* background-color: #f3fafa; */
}
.progress-profile2 .title {
  color: #000;
}
.progress-profile2 .left,
.progress-profile2 .right {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 4px solid #00abad;
  border-radius: 100px 0px 0px 100px;
  border-right: 0;
  transform-origin: right;
}

.progress-profile2 .left {
  animation: load1 1s linear forwards;
}

.progress-profile2:nth-of-type(2) .right,
.progress-profile2:nth-of-type(3) .right {
  animation: load2 0.5s linear forwards 1s;
}

.progress-profile2:last-of-type .right,
.progress-profile2:first-of-type .right {
  animation: load3 0.8s linear forwards 1s;
}

/* progress end */

.JHE-fixed-width {
  background-color: #fff;
  width: 82.5%;
  position: fixed;
  border-radius: 6px;
  height: 10%;
  top: 12%;
  left: 6%;
  box-shadow: 0px 3px 6px #00000017;
}
.JHE-fixed-width2 {
  width: 5%;
  position: fixed;
  border-radius: 6px;
  height: 10%;
  top: 12%;
  left: 89%;
}
.JHE-fixed-width3 {
  width: 5%;
  position: fixed;
  border-radius: 6px;
  height: 10%;
  top: 12%;
  left: 94.5%;
}
.JHE-fixed-width3 .grid-view span {
  font-size: 35px;
}
.JHE-fixed-width2 .table-view span {
  font-size: 30px;
}
.cancel-icon-color {
  color: #4b4b4bd6;
}
.PP-upload-img {
  width: 78px;
  height: 78px;
  border-radius: 50px;
}
.PP-upload-img2 {
  width: 34px;
  height: 34px;
  border-radius: 50px;
}
.primary-body-content2 {
  height: 75vh;
}
/* tab  responsive start */

@media screen and (min-width: 1000px) and (max-width: 1150px) {
  .navbar .navbar-nav .nav-item .nav-link {
    font-size: 13px;
  }
  .postjob-btn {
    font-size: 13px;
  }
  .login-btn {
    font-size: 13px;
  }
  .logo-img {
    width: 100px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0rem;
    padding-left: 0rem;
  }
}
@media screen and (min-width: 750px) and (max-width: 1150px) {
  .Banner {
    min-height: 50.5vh;
  }
  .section1 h1 {
    font-size: 25px;
  }
  .homesection1 .section-content1 p {
    padding-top: 5%;
  }
  .section1 p {
    font-size: 14px;
    letter-spacing: 1px;
  }
  .section1-regEmp {
    width: 38%;
  }
  .section1 img {
    width: 100px;
    height: 100px;
  }
}

/* tab  responsive end */

/* mobile  responsive start */
@media screen and (max-width: 748px) {
  .section1 h1 {
    font-size: 26px;
  }
  .homesection1 h3 {
    font-size: 24px;
  }
  .section1-regEmp {
    position: relative;
    width: 100%;
  }
  .homesection1 .section-content1::after {
    bottom: 0px;
  }
}
.toast-message {
  margin-top: 70px;
  z-index: 300000000000000 !important;
}

/* mobile  responsive end*/

.EditableTextBox {
  outline: none;
  border: none;
}
.EditableTextBox.active {
  border-bottom: 2px solid #05aaae;
}
.modal-content .modal-body .modal-register-bg {
  background: linear-gradient(180deg, #00abad, #099dd1);
  padding: 10% 0px;
  /* background-image:url(../src/images/modal-bg-img.png); */
}
.modal-content .modal-register-bg-image {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 90%;
  height: 70%;
  left: 20%;
}
.modal-content .section1 h1 {
  font-size: 25px;
}

@media screen and (max-width: 778px) {
  .modal-content .section1 h1 {
    font-size: 20px;
  }
  .modal-content .section2 .home-rdt-btn {
    padding: 12px 0px;
    font-size: 14px;
  }
  .modal-content .section1 p {
    font-size: 12px;
  }
  .modal-content .modal-body .modal-register-bg {
    padding: 0px 0px;
  }
}

#cf3 {
  position: relative;
  width: auto;
  height: 150px;
}

#cf3 img {
  position: absolute;
  left: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

@keyframes cf3FadeInOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

#cf3 img.top {
  animation-name: cf3FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 6s;
  animation-direction: alternate;
}

/* insights starts */
.insights-section {
  /* background-color: #fff; */
  width: 93.5%;
  position: fixed;
  border-radius: 6px;
  height: 87%;
  top: 12%;
  left: 6%;
  /* box-shadow: 0px 3px 6px #00000017; */
}
.insights-section .insights-overall-jobs {
  /* width: 37.5%;
  position: fixed;
  border-radius: 8px;
  height: 25%;
  top: 12%;
  left: 6%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017; */

  position: relative;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
}
.insights-section .insights-rtp {
  /* width: 24.5%;
  position: fixed;
  border-radius: 8px;
  height: 25%;
  top: 12%;
  left: 44%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017; */
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
}
.insights-section .insights-qtp {
  /* width: 30.5%;
  position: fixed;
  border-radius: 8px;
  height: 25%;
  top: 12%;
  left: 69%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017; */
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
}
.bg-color {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.bg-pink .text {
  color: #ad0054;
}
.bg-color small {
  font-size: 75%;
  font-weight: 400;
}
.insights-section .insights-map-section {
  /* width: 62.5%;
  position: fixed;
  border-radius: 8px;
  height: 61%;
  top: 38%;
  left: 6%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017; */
  border-radius: 8px;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
}
.bg-vio {
  background-color: #faeffa;
}
.bg-pink {
  background-color: #faeff4;
}
.bg-vio .text {
  color: #ad00ab;
}
.bg-vio .text2 {
  color: #0054ad;
}
.color-based-map .color-green:before {
  width: 12px;
  height: 12px;
  content: "";
  background-color: #00abad;
  display: block;
  margin: 7px 0px 0px -15px;
  position: absolute;
}
.color-based-map .color-blue:before {
  width: 12px;
  height: 12px;
  content: "";
  background-color: #5800ad;
  display: block;
  margin: 7px 0px 0px -15px;
  position: absolute;
}
.color-based-map .color-violet:before {
  width: 12px;
  height: 12px;
  content: "";
  background-color: #ad00ab;
  display: block;
  margin: 7px 0px 0px -15px;
  position: absolute;
}
.color-based-map .color-brown:before {
  width: 12px;
  height: 12px;
  content: "";
  background-color: #ad0054;
  display: block;
  margin: 7px 0px 0px -15px;
  position: absolute;
}
.color-based-map li {
  list-style-type: none;
  padding: 0px 12px;
}
.color-based-map small {
  font-size: 75%;
  font-weight: 400;
}
.insights-section .insights-date-section1 {
  /* width: 15%;
    position: fixed;
    border-radius: 8px;
    height: 35%;
    top: 38%;
    left: 69%;
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000017;
    text-align: center; */

  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
  text-align: center;
}
.insights-section .insights-date-section2 {
  /* width: 15%;
  position: fixed;
  border-radius: 8px;
  height: 35%;
  top: 38%;
  left: 84.5%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
  text-align: center; */
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
  text-align: center;
}
.bg-color-blue {
  background-color: #ebf2f9;
  border-radius: 8px;
  width: 100%;
  margin: auto;
  display: table;
  color: #0054ad;
  height: auto;
}
.bg-color-green-insights {
  background-color: #e3f6f6;
  border-radius: 8px;
  width: 101%;
  margin: auto;
  display: table;
  color: #00abad;
  height: auto;
}
.insights-section small {
  font-size: 75%;
  font-weight: 400;
}
.insights-date-section1 h6 {
  height: 40px;
}
.insights-date-section2 h6 {
  height: 40px;
}
.insights-section .insights-rejected-reason {
  /* width: 30.5%;
    position: fixed;
    border-radius: 8px;
    height: 25%;
    top: 74%;
    left: 69%;
    text-align: center; */
  border-radius: 8px;
  text-align: center;
}
.insights-section select.form-select.text-blue.postjob-btn:focus-visible {
  border: none;
  outline: 1px solid #d9e9e9;
}
.insights-rejected-reason .heading {
  border-bottom: 3px solid #00abad;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
  border-radius: 8px;
}
.insights-section .btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
/* insights end */

/* companies employee start */
.ce-section .input-group-text {
  background-color: #e9ecef;
  border: 1px solid #e9ecef;
}
.ce-section .form-control {
  background-color: #e9ecef;
  border: 1px solid #e9ecef;
  padding: 0.375rem 0.2rem;
}
.ce-section .form-control:focus {
  color: #495057;
  background-color: #e9ecef;
  border-color: #e9ecef;
  outline: 0;
  box-shadow: none;
}
.ce-card {
  border: 1px solid #fff;
  box-shadow: 0px 0px 10px #00000017;
  border-radius: 8px;
  height: auto;
}
.ce-font {
  font-size: 30px;
}

.ci-section {
  background-color: #fff0;
  width: 93.5%;
  position: fixed;
  border-radius: 6px;
  height: 87%;
  top: 12%;
  left: 6%;
  /* overflow: auto; */
  /* box-shadow: 0px 3px 6px #00000017; */
}
.ci-bannerImage {
  opacity: 1;
  display: block;
  width: 100%;
  height: 173px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}
.ci-bannerLogo {
  width: 75px;
  height: 75px;
}
.ci-logo-btn {
  display: flex;
  position: relative;
  top: -25px;
  align-items: center;
  justify-content: space-between;
}
.ci-logoBG {
  background-color: #fff;
  border-radius: 8px;
  padding: 2%;
}
.ci-banner-bottom {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.all-comp-section {
  border: 1px solid #000;
  padding: 5%;
  border-radius: 8px;
  height: fit-content;
}
.cut-text2 {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  /* height: 1.2em; */
  white-space: nowrap;
}
.cut-text {
  width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  /* height: 1.2em; */
  white-space: nowrap;
}
.ce-section .rate-area:not(:checked) > label {
  float: right;
  width: 0.8em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 150%;
  color: lightgrey;
  margin-bottom: 0px;
}
.ce-card2 {
  border: 1px solid #fff;
  box-shadow: 0px 0px 10px #00000017;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}
.ci-tab-section {
  /* background-color: #fff; */
  width: 100%;
  position: relative;
  border-radius: 6px;
  left: 0;
  top: 0px;
  box-shadow: 0px 3px 6px #00000017;
}
.ce-ceo-img {
  width: 100%;
  height: 230px;
}
.color-gray {
  color: #6b6b6b;
}
.bg-gray {
  background-color: #e4e4e4;
  color: rgb(0, 0, 0);
  border-radius: 25px;
}
.icon-size {
  font-size: 20px;
  cursor: pointer;
}

.companies-tab-section {
  background-color: #fff;
  border-bottom: 3px solid #00abad;
  border-radius: 6px 6px 0px 0px;
}
.Companies-Overview {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
  border-radius: 0px 0px 6px 6px;
}
.reviews-heading-comp {
  background-color: #fff;
}
.cwar-section {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000017;
}
.companies-tab-section {
  background-color: #fff;
  border-bottom: 3px solid #00abad;
  border-radius: 6px 6px 0px 0px;
}
.Companies-Overview {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000017;
  border-radius: 0px 0px 6px 6px;
}
.reviews-heading-comp {
  background-color: #fff;
}
.cwar-section {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000017;
}
.ci-tab-section2 .section2 form {
  margin: 0px 10%;
}
.Companies-Reviews {
  background-color: rgba(255, 255, 255, 0);
}
.selected-resume-css {
  display: flex;
}
.dropdown-container .css-1hi07cd-multiValue {
  background-color: hsl(0deg 0% 98%) !important;
}
/* companies employee end */

div span.text-capitalize {
  word-break: break-all;
}
.primary-body-content2 small {
  cursor: grab;
  transform: translate(0, 0);
}
/* blogs start */

.BlogsHome {
  margin-top: 73px;
}
.blogs-topic {
  display: inline-block;
}
.BlogsHome .item {
  background-color: #eee;
  padding: 1px 0px;
  border-radius: 25px;
}
.BlogsHome .owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  height: 37px;
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.BlogsHome .item:hover,
.BlogsHome .item.active {
  background-color: #00abad;
  padding: 1px 0px;
  color: #fff;
  border-radius: 25px;
}
.BlogsHome .owl-theme .owl-nav {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -50px;
  font-size: 33px;
  -webkit-tap-highlight-color: transparent;
}
.BlogsHome .owl-carousel .owl-nav button.owl-prev {
  background: none;
  color: inherit;
  position: absolute;
  left: -20px;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.BlogsHome .owl-carousel .owl-nav button.owl-next {
  background: none;
  color: inherit;
  position: absolute;
  right: -20px;
  border: none;
  padding: 0 !important;
  font: inherit;
}
.BlogsHome button:focus {
  outline: 1px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.BlogsHome .form-control {
  background-color: #f3fafa;
  border: 1px solid #e9ecef;
  padding: 0.375rem 0.2rem;
}
.BlogsHome .input-group-text {
  background-color: #f3fafa;
  border: 1px solid #f3fafa;
}
.BlogsHome .form-control:focus {
  color: #495057;
  background-color: #f3fafa;
  border-color: #f3fafa;
  border: 1px solid #e9ecef;
  outline: 0;
  box-shadow: none;
}
.blogs-topics li {
  display: inline-block;
  padding: 2px 8px;
  font-size: 14px;
  margin: 3px;
  background-color: #e4e4e4;
  border-radius: 25px;
  margin-bottom: 10px;
}
.BlogsHome a {
  color: #000000;
  text-decoration: none;
}
.BlogsHome a:hover {
  color: #000000;
  text-decoration: none;
}
.blog-indi-img {
  width: 55px;
  height: auto;
}
.blogs-profile-img {
  position: absolute;
  width: 100%;
  bottom: 10px;
}
.blogs-profile {
  position: relative;
}
/* blogs end */
/* Admin */
.btnalign {
  display: flex;
  justify-content: space-evenly;
}

/* admin design start*/
.css-pdct74-MuiTablePagination-selectLabel {
  margin-bottom: 0;
}
.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 0;
}
.css-11mde6h-MuiPaper-root {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 0%);
}
.scroll-content-admin {
  height: 75vh;
  overflow-y: auto;
}
/* admin design end*/
.scroll-jobs-employee {
  height: 65vh;
  overflow-y: auto;
}
.static-date {
  position: absolute;
  bottom: 5px;
}

.tablelanguage {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.tablelanguage td,
.tablelanguage th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tabletr {
  position: sticky;
  background-color: #fff;
  top: 0;
  border: 1px solid #000;
}
.tableFixHead {
  overflow: auto;
  height: 100px;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
}

.selected-lang {
  overflow-y: auto;
  height: 40vh;
}
.nav-logout-dropdown{
  position: absolute;
  transform: translate3d(-128px, 40px, 0px);
  top: 0 !important;
  left: 0 !important;
  overflow: visible;
  will-change: transform;
}
